import * as React from "react"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout"

// markup
const NotFoundPage = () => {
  const intl = useIntl()
  return (
    <Layout 
        title={intl.formatMessage({id: "Page not found"})} 
        isHome={false}>
        
          <div className="container">
            <article className="content">
               <h1 className="content-title">{intl.formatMessage({id: "Page not found"})}</h1>

                  {/* The main page content */}
                  <section
                    className="content-body load-external-scripts">
                      <p>
                      {intl.formatMessage({id: "Sorry, we couldn’t find what you were looking for."})}
                      </p>
                      <Link to="/">Go home</Link>.
                  </section>
            </article>
          </div>
      </Layout>
  )
}

export default NotFoundPage
